<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png"
             alt />
        <span class="center">企业管理</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">企业列表</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">企业详情</span>
      </div>
    </div>
    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>基础字段</span>
          </div>
        </div>
        <el-descriptions class="margin-top"
                         style="margin-top: 20px"
                         :column="3"
                         :size="size">
          <el-descriptions-item label="ID号">{{ detailInfo.code}}</el-descriptions-item>
          <!-- <el-descriptions-item label="超级管理员账号">
            <span v-if="detailInfo.super_user">
              {{
              detailInfo.super_user.phone
              }}
            </span>
          </el-descriptions-item>-->

          <el-descriptions-item label="企业名称">
            {{
            detailInfo.name
            }}
          </el-descriptions-item>
          <el-descriptions-item label="企业统一信用编码">
            {{
            detailInfo.idCardNumber
            }}
          </el-descriptions-item>
          <el-descriptions-item label="企业编号">
            <span v-if="detailInfo.config">
              {{
              detailInfo.config.character
              }}
            </span>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="企业logo">
            <div style="display: flex; align-items: center">
              <el-tooltip class="item" effect="dark" content="点击查看图片" placement="top-start">
                <i
                  style="font-size: 26px"
                  class="el-icon-picture-outline font-img"
                  @click="showImg(detailInfo.config.styleLogo, '查看照片')"
                ></i>
              </el-tooltip>
            </div>
          </el-descriptions-item>-->
          <el-descriptions-item label="超管名称">
            {{
            detailInfo.config.username
            }}
          </el-descriptions-item>
          <el-descriptions-item label="超管账号">
            {{
            detailInfo.config.userPhone
            }}
          </el-descriptions-item>
          <el-descriptions-item label="备用电话">
            {{
            detailInfo.config.smsPhone
            }}
          </el-descriptions-item>
          <el-descriptions-item label="邮箱">
            {{
            detailInfo.config.email
            }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>微信配置</span>
          </div>
        </div>
        <!-- <el-descriptions class="margin-top" style="margin-top:20px" :column="3" :size="size"> -->
        <el-descriptions class="margin-top"
                         :column="3"
                         :size="size">
          <el-descriptions-item label="微信appid">
            {{
            detailInfo.config.wx_appid
            }}
          </el-descriptions-item>
          <el-descriptions-item label="微信秘钥">
            {{
            detailInfo.config.wx_secret
            }}
          </el-descriptions-item>
          <el-descriptions-item label="微信服务器认证文件名称">
            {{
            detailInfo.config.wx_serverfile
            }}
          </el-descriptions-item>
          <el-descriptions-item label="微信服务器认证文件内容">
            {{
            detailInfo.config.wx_serverfileContent
            }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>页面个性化配置</span>
          </div>
        </div>
        <el-descriptions class="margin-top"
                         style="margin-top: 20px"
                         :column="3"
                         :size="size">
          <el-descriptions-item label="公司电话">{{detailInfo.config.stylePhone}}</el-descriptions-item>
          <el-descriptions-item label="登录页背景图">
            <el-tooltip class="item"
                        effect="dark"
                        content="点击查看图片"
                        placement="top-start">
              <i class="el-icon-picture-outline font-img"
                 style="font-size: 26px"
                 @click="showImg(detailInfo.config.styleBackImg, '查看照片')"></i>
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item label="企业Logo">
            <el-tooltip class="item"
                        effect="dark"
                        content="点击查看图片"
                        placement="top-start">
              <i class="el-icon-picture-outline font-img"
                 style="font-size: 26px"
                 @click="showImg(detailInfo.config.styleLogo, '查看照片')"></i>
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item label="公司地址">{{detailInfo.config.styleAddress}}</el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>计费信息</span>
          </div>
        </div>
        <div class="text item">
          <el-descriptions class="margin-top"
                           style="margin-top: 20px"
                           :column="3"
                           :size="size">
            <el-descriptions-item label="购买的短信数">
              {{
              detailInfo.config.sms_buy_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="剩余短信数">
              {{
              detailInfo.config.sms_surplus_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="购买的合同数">
              {{
              detailInfo.config.contract_buy_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="剩余合同数">
              {{
              detailInfo.config.contract_surplus_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="购买的认证数">
              {{
              detailInfo.config.auth_buy_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="剩余认证数">
              {{
              detailInfo.config.auth_surplus_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="购买的发放笔数">
              {{
              detailInfo.config.pay_buy_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="剩余发放笔数">
              {{
              detailInfo.config.pay_surplus_num
              }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>
      <el-card class="box-card">
        <Account @close="close()"
                 :dialogFormVisible.sync="dialogFormVisible"
                 :dialogTitle.sync="dialogTitle"
                 :code.sync="code"
                 :item.sync="item" />
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>账户信息</span>
          </div>
          <div>
            <el-button type="text"
                       @click="openDialog">新增账户</el-button>
          </div>
        </div>
        <div class="bank_Calss"
             v-for="(item,index) in detailInfo.platform"
             :key="index">
          <div class="class_top">
            <div>
              <span>{{item.bankName}}</span>
              <span>
                (
                <span v-if="item.bankName==='网商银行'"
                      style="color:#aaaaaa">
                  <span v-if="item.RegisterStatus==='0'">审核中</span>
                  <span v-if="item.RegisterStatus==='1'">成功</span>
                  <span v-if="item.RegisterStatus==='2'">失败</span>，
                  <span v-if="item.enable&&item.RegisterStatus==='1'">使用中</span>
                  <span v-else>已禁用</span>
                  <span v-if="item.RegisterStatus==='1'">
                    ,
                    <el-button type="text"
                               v-if="item.enable"
                               @click="getDeactivate('停用',item.bankName,item.platformCode)"
                               style="color:red">停用</el-button>
                    <el-button type="text"
                               v-else
                               @click="getDeactivate('启用',item.bankName,item.platformCode)">
                      <span>启用</span>
                    </el-button>
                  </span>
                </span>
                <span v-if="item.bankName==='平安银行'"
                      style="color:#aaaaaa">
                  <span v-if="item.enable">使用中</span>
                  <span v-else>已禁用</span>
                  ,
                  <span>
                    <el-button type="text"
                               v-if="item.enable"
                               @click="getDeactivate('停用',item.bankName,item.platformCode)"
                               style="color:red">停用</el-button>
                    <el-button type="text"
                               v-else
                               @click="getDeactivate('启用',item.bankName,item.platformCode)">
                      <span>启用</span>
                    </el-button>
                  </span>
                </span>
                )
                <!-- <span
                  v-if="item.bankName==='网商银行'"
                  style="color:#4977fc"
                  @click="getRefresh()"
                >刷新</span>-->
                <!-- <span v-else style="color:#4977fc" @click="getVaild(item.platformCode)">激活</span> -->
              </span>
            </div>
            <div>
              <el-button type="text"
                         v-if="item.isDefault"
                         :disabled="item.isDefault"
                         @click="getDefault(item.platformCode)">默认账户</el-button>
              <el-button type="text"
                         v-else
                         @click="getDefault(item.platformCode)">设为默认账户</el-button>
              <el-button type="text"
                         @click="editAccount(item)">编辑</el-button>
            </div>
          </div>
          <el-descriptions style="padding:20px"
                           v-if="item.bankName==='平安银行'">
            <el-descriptions-item label="渠道名称">{{item.platformName}}</el-descriptions-item>
            <el-descriptions-item label="账户名称">{{item.accountName}}</el-descriptions-item>
            <el-descriptions-item label="账户卡号">{{item.accountCode}}</el-descriptions-item>
            <!-- <el-descriptions-item label="通知回调地址">
          <span v-if="item.cbuType==='LBG_CSF'">零工保测试</span>
          <span v-if="item.cbuType==='FXF_CSF'">放薪付测试</span>
          <span v-if="item.cbuType==='FQW_CSF'">非全用工测试</span>
            </el-descriptions-item>-->
            <el-descriptions-item label="费项名称">{{item.pingAnB2bicBusiName}}</el-descriptions-item>
            <el-descriptions-item label="行内费项代码">{{item.pingAnB2bicBusiType.split(',')[0]}}</el-descriptions-item>
            <el-descriptions-item label="跨行费项代码">
              <span v-if="item.pingAnB2bicBusiType.split(',')[1]">{{item.pingAnB2bicBusiType.split(',')[1]}}</span>
            </el-descriptions-item>
            <el-descriptions-item label="行内协议编号">{{item.pingAnB2bicAgreeNo.split(',')[0]}}</el-descriptions-item>
            <el-descriptions-item label="跨行协议编号">
              <span v-if="item.pingAnB2bicAgreeNo.split(',')[1]">{{item.pingAnB2bicAgreeNo.split(',')[1]}}</span>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="padding:20px"
                           v-else>
            <el-descriptions-item label="渠道名称">{{item.platformName}}</el-descriptions-item>
            <!-- <el-descriptions-item v-if="item.FailReason"
                                  label="拒绝原因">{{item.FailReason}}</el-descriptions-item> -->

            <!-- <el-descriptions-item label="开户状态">
              <el-tag v-if="item.RegisterStatus==='1'" type="success">成功</el-tag>
              <el-tag v-if="item.RegisterStatus==='2'" type="info">失败</el-tag>
            </el-descriptions-item> -->
            <!-- <el-descriptions-item label="账户名称">{{item.MerchantMyBankCardName}}</el-descriptions-item>
            <el-descriptions-item label="账户卡号">{{item.MerchantMyBankCardNo}}</el-descriptions-item> -->
            <!-- <el-descriptions-item label="通知回调地址">
          <span v-if="item.cbuType==='LBG_CSF'">零工保测试</span>
          <span v-if="item.cbuType==='FXF_CSF'">放薪付测试</span>
          <span v-if="item.cbuType==='FQW_CSF'">非全用工测试</span>
            </el-descriptions-item>-->
          </el-descriptions>
        </div>
      </el-card>
    </el-card>
    <el-dialog class="showImgDialog"
               width="680px"
               :center="true"
               :title="dialogTitle"
               :visible.sync="showImgVisible"
               :append-to-body="true">
      <div class="imgBox">
        <img style="width: 600px;"
             :src="dialogImgUrl"
             class="img-resource"
             alt />
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { api } from '../../api/base/index';
export const customerInfo = api()('enterprise.info.json');
export const enterprisePaySetdefault = api()('enterprise.pay.setdefault.json');
export const enterprisePaySetStatus = api()('enterprise.pay.setStatus.json');
export const enterpriseBankCardPinganValid = api()('enterprise.bankCard.pingan.valid.json');

//网商更新状态
export const enterpriseBankCardCheckProgress = api()('enterprise.bankCard.checkProgress.json');
export default {
  components: {
    Account: () => import('./AccountInfo.vue')
  },
  data () {
    return {
      code: '',
      detailInfo: {

      },
      dialogTitle: '',
      showImgVisible: false,
      passShow: false,
      dialogImgUrl: '',
      dialogFormVisible: false,
      size: '',
      item: {}
    }
  },
  created () {
    this.code = this.$route.query.code;
    this.logoData()
  },
  methods: {
    showImg (img, title) {
      this.dialogImgUrl = img;
      this.dialogTitle = title;
      console.log(this.dialogImgUrl);
      this.showImgVisible = true;
    },
    getRefresh () {
      this.$message.info('此功能未开发')
      // const params = {
      //   ApplyOrderNo: ''
      // };
      // enterpriseBankCardCheckProgress(params).then((res) => {
      //   this.cbuTypeList = res
      // });

    },
    getVaild (code) {
      const params = {
        platformCode: code
      };
      enterpriseBankCardPinganValid(params).then((res) => {
        console.log(res)
        this.logoData()

      });

    },
    openDialog () {

      this.dialogTitle = '新增账户信息'

      this.dialogFormVisible = true
    },
    editAccount (item) {
      this.item = item
      this.dialogTitle = '编辑账户信息'
      this.dialogFormVisible = true
    },

    getDeactivate (title, bankName, code) {
      if (title === '启用') {
        this.$alert(`是否启用${bankName}`, '启用账户', {
          confirmButtonText: '确定',
          callback: () => {
            const params = {
              code: code,
              enable: true
            };
            enterprisePaySetStatus(params).then((res) => {
              console.log(res, 222);
              this.$message({
                type: 'success',
                message: '启用账户成功'
              });
              this.logoData()
            });
          }
        });
      }
      else {
        this.$alert(`是否停用${bankName}`, '停用账户', {
          confirmButtonText: '确定',
          callback: () => {
            const params = {
              code: code,
              enable: true
            };
            enterprisePaySetStatus(params).then((res) => {
              console.log(res, 222);
              this.$message({
                type: 'error',
                message: '停用账户成功'
              });
              this.logoData()
            });
          }
        });
      }
    },
    getDefault (code) {
      const params = {
        code: code,
        enterpriseCode: this.code
      };
      enterprisePaySetdefault(params).then((res) => {
        console.log(res, 222);
        this.$message({
          type: 'success',
          message: '设置成功'
        });
        this.logoData()
      });

    },
    async close () {
      this.dialogFormVisible = false
      await this.logoData();
    },
    logoData () {
      const params = {
        code: this.code
      };
      customerInfo(params).then((res) => {
        this.detailInfo = res;


      });
    }
  },
}
</script>
<style lang="scss">
.text-align {
  display: flex;
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
.bank_Calss {
  width: 100%;
  border-radius: 5px;
  border: #8798af solid 1px;
  margin: 10px 0;
  .class_top {
    background: #f2f2f2;
    padding: 5px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.el-descriptions-item__content {
  white-space: normal;
  word-wrap: break-word;
  width: 200px;
}
.el-divider {
  background: #e5e5e5 !important;
}
</style>